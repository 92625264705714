var site = site || {};

(function($) {
  site.popovers = {
    conf: {
      // Moved configuration settings to here for now.
      // Might need to pull this out again to perlgem. Maybe not.
      "popover_display_modal_mask":1,
      "display_po_immediately":1,
      "last_source":"",
      "homepage":1,
      "lang_pref":48,
      "popover_filename":"/promotions/includes/popovers/interstitial.tmpl",
      "popover_style_left":"0px",
      "callback_name":"undefined",
      "cookie_name":"optInPopup30Days",
      "cookie_bypass_name" : "LOCALE",
      "set_popover_cookie_only":0,
      "cookie_duration":"30"
    },

    resetPopUpOffer: function() {
      var checkForPopUpOffer = $.cookie('hide_popup_offer'); // Get Pop Up Offer cookie

      if( checkForPopUpOffer == "yes") {
        console.log('Pop Up Cookie Is Set');
        $.cookie('hide_popup_offer', null);
        console.log('Pop Up Cookie Is Cleared');
      }
    },

    initConf: function() {
      if ( site.popovers.conf
            && site.popovers.conf.popover_filename
        ) {
        site.popovers.process();
      }
    },
    init: function () {
      /*
      $.ajax('/includes/sitewide_popovers.tmpl', {
        dataType: 'json',
        success: function(response) {
          popovers.conf = response;
  	if (popovers.conf.popover_filename) {
  	  popovers.process();
  	}
        }
      });
      */
      try {
        //site.popovers.initConf(popovers_conf);
        site.popovers.initConf();
      }
      catch(err) {
        console.log(err);
      }
      
    },
  
    process: function () {
      var siteWidePopOverPendingOrVisible = true;
      var $ = jQuery;
      if (typeof(suppressSitewidePopovers) != 'undefined'
          || generic.cookie(site.popovers.conf.cookie_name)
          || site.popovers.conf.set_popover_cookie_only ){
        return;
      }

      // If interstitial has already been seen on another locale, just set the cookie.
      // Mainly done for the motionpoint servers and not registering the viewed cookie.
      if ( site.popovers.checkViewed() ) {
        site.popovers.setCookie();
        return null;
      };

      var url = site.popovers.conf.popover_filename;
      var includeBackgroundFlag = site.popovers.conf.popover_display_modal_mask;

      $.ajax(url, {
        dataType: 'html',
        success: function(html) {
          //console.log(html);
  	var popup_timer = '';
          var close_popup_timer = '';
          var popover_div       = $('<div id="popover_container" ></div>').html(html).get(0);
          var img_width = $('img', popover_div).attr('width') || $('table', popover_div).attr('data-width');
          var img_height = $('img', popover_div).attr('height')|| $('table', popover_div).attr('data-height');
                              
          var left = site.popovers.get_left_position(popover_div);
                              
          // if the offers shade has not been seen, dont show popover for 
          //  5 seconds until after the shade automatically rolls back up, 
          //  set in globalnav.js, unless the display_immediately flag is
          //  defined in the config file
          var time_to_wait = (site.popovers.conf.display_po_immediately) ? 0 : site.popovers.conf.homepage ? 30000
  	  : (!generic.cookie("viewed_offers_shade")) ? 2500
  	  : 2500
  	  ;
          window.setTimeout(function() {
            generic.overlay.launch({
  	    content: popover_div, 
  	    includeBackground: includeBackgroundFlag,
  	    fixed: 'true',
  	    appearDuration: 1.0,
        trapFocus: false,
  	    cssStyle: {
  	      overflow: 'visible ',
  	      border: 'none',
  	      padding: 0,
  	      backgroundColor: 'transparent',
  	      height: img_height,
  	      width: img_width,
  	      //left: popovers.conf.popover_style_left,
  	      position: 'fixed'
  	    }
  	  });
  
  	  var callback = site.popovers.conf.callback_name;
  	  if (typeof(callback) === "function") {
  	    callback();
  	  }
          site.popovers.setCookie();
          },time_to_wait);
        }
      });                                          
    },

    setCookie : function() {
      var expDate=new Date();
      expDate.setDate(expDate.getDate() + site.popovers.conf.cookie_duration);
      var expDateString = expDate.toGMTString();

      generic.cookie(site.popovers.conf.cookie_name, '1',  {path: '/', expires: expDateString });
      generic.cookie(site.popovers.conf.cookie_name + '_CREATED_THIS_SESSION', '1',  {path: '/', expires: '0' });
      site.popovers.resetPopUpOffer();
    },

    checkViewed : function () {
      var query = window.location.search.toString().split('?')[1] || "";
      var beenViewed = query ? query.match(site.popovers.conf.cookie_bypass_name) : '';

      return beenViewed;
    },

    initEmailSMSForm: function () {
      var onSuccess = function(jsonRpcResponse) {
        site.popovers.close_sitewide_popover();
      }
  
      var onFailure = function(jsonRpcResponse) {
        var errorObjectsArray = jsonRpcResponse.getMessages();
        generic.showErrors(errorObjectsArray, $(popover_signup_error))
      }
  
      emailSignupForm($('#pop_sms_email_container'), onSuccess, onFailure);
    },
  
    close_sitewide_popover: function (){
      siteWidePopOverPendingOrVisible = false;
      generic.overlay.hide();
    },
  
    // need this width for centering popup
    getLargestWidth: function (POContainer) {
      var blocks = $('div',POContainer);
      var largestWidth = 0;
      blocks.each( function(n) {
  		   var nWidth = $(this).css('width');
  		   if (nWidth != null){
  		     nWidth = Number(nWidth.sub('px',''));
  		     largestWidth = (nWidth > largestWidth) ? nWidth : largestWidth;
  		   }
  		 });
      return largestWidth;
    },
  
    get_left_position: function (POContainer) {
      var width = site.popovers.getLargestWidth(POContainer);
      var windowWidth = $.browser.msie ? document.documentElement.clientWidth : window.innerWidth;
    
      return (windowWidth / 2) - (width/2);
    },
  
    submit_popup_email: function (emailType, thank_you_div){
      $('#popover_signup_error').get(0).style.display = 'none';
      $('#email_thanks').get(0).style.display = 'none';
      var data;
      var emailAddy;
      var smsNumber;
      var emailAddyElement = $('#popover_signup_email_address');
      emailAddy = emailAddyElement.val();
      var phoneNumberElement = $('#popover_signup_sms');
      smsNumber = phoneNumberElement.val();
      var data;
      if ( emailAddy ) {
        //console.log("email address");
        //console.log(emailAddy);
        data = [{
  	"PC_EMAIL_ADDRESS": emailAddy,
  	"ACTION": 'set',
  	"_SUBMIT": "email_signup",
  	"form--email_signup--field--PC_EMAIL_ADDRESS": "1",
  	"LAST_SOURCE" : site.popovers.conf.last_source,
  	"ORIGINAL_SOURCE" : site.popovers.conf.last_source,
  	"LANGUAGE_ID"   : site.popovers.conf.lang_pref,
  	"CH_EMAIL_TYPE" : emailType
        }];
      } else if ( smsNumber ) {
        //console.log("phone number");
        //console.log(smsNumber);
        data = [{
  	"MOBILE_PHONE": smsNumber,
  	"SMS_PROMOTIONS" : "1",
  	"ACTION": 'set',
  	"_SUBMIT": "sms",
  	"form--email_signup--field--PC_EMAIL_ADDRESS": "1",
  	"LAST_SOURCE" : site.popovers.conf.last_source,
  	"ORIGINAL_SOURCE" : site.popovers.conf.last_source,
  	"LANGUAGE_ID"   : site.popovers.conf.lang_pref,
  	"CH_EMAIL_TYPE" : emailType
        }];
      } else {
        $('#popover_signup_error').get(0).style.display = 'inline-block';
        return 0;
      }
      generic.jsonrpc.fetch({ 
        method : 'email.signup',
  	  params: data,
  	  onSuccess: function(jsonRpcResponse) { 
  	  if ( emailAddy ) {
  	    $('#email_thanks').get(0).style.display = 'block';
  	    $('.email_input').get(0).style.display = 'none';
  	    setTimeout(function(){generic.overlay.hide()}, 3500);
  	  }
  	  if ($('#' + thank_you_div)){
  	    //console.log("there's the thank_you_div");
  	    //$('#emailsms_input').get(0).style.display = 'none';
  	    //$('#' + thank_you_div).get(0).style.display = 'block';
  	    
  	  }
  	},
  	  onFailure: function(jsonRpcResponse) { 
  	  // for some reason generic.jsonrpc.fetch always returns failure and we 
  	  // end up here in IE even though the email addy is submitted and 
  	  // the user receives an email needs further investigation 
  	  // 11/21/2013 --rjm--
  	  //$.browser.msie ? $('#' + thank_you_div).get(0).style.display = 'inline-block' : $('#popover_signup_error').get(0).style.display = 'inline-block'; return 0;
  	  var errorObjectsArray = jsonRpcResponse.getMessages();
  	  generic.showErrors(errorObjectsArray, $(popover_signup_error));
  	  return 0;
  	} 
  	
        });                               
      return true;
    }
  };

/*  $("#loading_popup").on("popupafterclose", function () {
          //any action you want like opening another popup
          onloadpopupOpen();

  })
*/
  ($)(function(){
    if (Drupal.settings.enable_interstitial) {
      site.popovers.init();
    }
  });
})(jQuery);
